import { Keyframes, unit } from '@ant-design/cssinjs';
import { genStyleHooks, mergeToken } from '../../theme/internal';
const skeletonClsLoading = new Keyframes(`ant-skeleton-loading`, {
    '0%': {
        backgroundPosition: '100% 50%',
    },
    '100%': {
        backgroundPosition: '0 50%',
    },
});
const genSkeletonElementCommonSize = (size) => ({
    height: size,
    lineHeight: unit(size),
});
const genSkeletonElementAvatarSize = (size) => (Object.assign({ width: size }, genSkeletonElementCommonSize(size)));
const genSkeletonColor = (token) => ({
    background: token.skeletonLoadingBackground,
    backgroundSize: '400% 100%',
    animationName: skeletonClsLoading,
    animationDuration: token.skeletonLoadingMotionDuration,
    animationTimingFunction: 'ease',
    animationIterationCount: 'infinite',
});
const genSkeletonElementInputSize = (size, calc) => (Object.assign({ width: calc(size).mul(5).equal(), minWidth: calc(size).mul(5).equal() }, genSkeletonElementCommonSize(size)));
const genSkeletonElementAvatar = (token) => {
    const { skeletonAvatarCls, gradientFromColor, controlHeight, controlHeightLG, controlHeightSM } = token;
    return {
        [skeletonAvatarCls]: Object.assign({ display: 'inline-block', verticalAlign: 'top', background: gradientFromColor }, genSkeletonElementAvatarSize(controlHeight)),
        [`${skeletonAvatarCls}${skeletonAvatarCls}-circle`]: {
            borderRadius: '50%',
        },
        [`${skeletonAvatarCls}${skeletonAvatarCls}-lg`]: Object.assign({}, genSkeletonElementAvatarSize(controlHeightLG)),
        [`${skeletonAvatarCls}${skeletonAvatarCls}-sm`]: Object.assign({}, genSkeletonElementAvatarSize(controlHeightSM)),
    };
};
const genSkeletonElementInput = (token) => {
    const { controlHeight, borderRadiusSM, skeletonInputCls, controlHeightLG, controlHeightSM, gradientFromColor, calc, } = token;
    return {
        [skeletonInputCls]: Object.assign({ display: 'inline-block', verticalAlign: 'top', background: gradientFromColor, borderRadius: borderRadiusSM }, genSkeletonElementInputSize(controlHeight, calc)),
        [`${skeletonInputCls}-lg`]: Object.assign({}, genSkeletonElementInputSize(controlHeightLG, calc)),
        [`${skeletonInputCls}-sm`]: Object.assign({}, genSkeletonElementInputSize(controlHeightSM, calc)),
    };
};
const genSkeletonElementImageSize = (size) => (Object.assign({ width: size }, genSkeletonElementCommonSize(size)));
const genSkeletonElementImage = (token) => {
    const { skeletonImageCls, imageSizeBase, gradientFromColor, borderRadiusSM, calc } = token;
    return {
        [skeletonImageCls]: Object.assign(Object.assign({ display: 'flex', alignItems: 'center', justifyContent: 'center', verticalAlign: 'top', background: gradientFromColor, borderRadius: borderRadiusSM }, genSkeletonElementImageSize(calc(imageSizeBase).mul(2).equal())), { [`${skeletonImageCls}-path`]: {
                fill: '#bfbfbf',
            }, [`${skeletonImageCls}-svg`]: Object.assign(Object.assign({}, genSkeletonElementImageSize(imageSizeBase)), { maxWidth: calc(imageSizeBase).mul(4).equal(), maxHeight: calc(imageSizeBase).mul(4).equal() }), [`${skeletonImageCls}-svg${skeletonImageCls}-svg-circle`]: {
                borderRadius: '50%',
            } }),
        [`${skeletonImageCls}${skeletonImageCls}-circle`]: {
            borderRadius: '50%',
        },
    };
};
const genSkeletonElementButtonShape = (token, size, buttonCls) => {
    const { skeletonButtonCls } = token;
    return {
        [`${buttonCls}${skeletonButtonCls}-circle`]: {
            width: size,
            minWidth: size,
            borderRadius: '50%',
        },
        [`${buttonCls}${skeletonButtonCls}-round`]: {
            borderRadius: size,
        },
    };
};
const genSkeletonElementButtonSize = (size, calc) => (Object.assign({ width: calc(size).mul(2).equal(), minWidth: calc(size).mul(2).equal() }, genSkeletonElementCommonSize(size)));
const genSkeletonElementButton = (token) => {
    const { borderRadiusSM, skeletonButtonCls, controlHeight, controlHeightLG, controlHeightSM, gradientFromColor, calc, } = token;
    return Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({ [skeletonButtonCls]: Object.assign({ display: 'inline-block', verticalAlign: 'top', background: gradientFromColor, borderRadius: borderRadiusSM, width: calc(controlHeight).mul(2).equal(), minWidth: calc(controlHeight).mul(2).equal() }, genSkeletonElementButtonSize(controlHeight, calc)) }, genSkeletonElementButtonShape(token, controlHeight, skeletonButtonCls)), { [`${skeletonButtonCls}-lg`]: Object.assign({}, genSkeletonElementButtonSize(controlHeightLG, calc)) }), genSkeletonElementButtonShape(token, controlHeightLG, `${skeletonButtonCls}-lg`)), { [`${skeletonButtonCls}-sm`]: Object.assign({}, genSkeletonElementButtonSize(controlHeightSM, calc)) }), genSkeletonElementButtonShape(token, controlHeightSM, `${skeletonButtonCls}-sm`));
};
// =============================== Base ===============================
const genBaseStyle = (token) => {
    const { componentCls, skeletonAvatarCls, skeletonTitleCls, skeletonParagraphCls, skeletonButtonCls, skeletonInputCls, skeletonImageCls, controlHeight, controlHeightLG, controlHeightSM, gradientFromColor, padding, marginSM, borderRadius, titleHeight, blockRadius, paragraphLiHeight, controlHeightXS, paragraphMarginTop, } = token;
    return {
        [componentCls]: {
            display: 'table',
            width: '100%',
            [`${componentCls}-header`]: {
                display: 'table-cell',
                paddingInlineEnd: padding,
                verticalAlign: 'top',
                // Avatar
                [skeletonAvatarCls]: Object.assign({ display: 'inline-block', verticalAlign: 'top', background: gradientFromColor }, genSkeletonElementAvatarSize(controlHeight)),
                [`${skeletonAvatarCls}-circle`]: {
                    borderRadius: '50%',
                },
                [`${skeletonAvatarCls}-lg`]: Object.assign({}, genSkeletonElementAvatarSize(controlHeightLG)),
                [`${skeletonAvatarCls}-sm`]: Object.assign({}, genSkeletonElementAvatarSize(controlHeightSM)),
            },
            [`${componentCls}-content`]: {
                display: 'table-cell',
                width: '100%',
                verticalAlign: 'top',
                // Title
                [skeletonTitleCls]: {
                    width: '100%',
                    height: titleHeight,
                    background: gradientFromColor,
                    borderRadius: blockRadius,
                    [`+ ${skeletonParagraphCls}`]: {
                        marginBlockStart: controlHeightSM,
                    },
                },
                // paragraph
                [skeletonParagraphCls]: {
                    padding: 0,
                    '> li': {
                        width: '100%',
                        height: paragraphLiHeight,
                        listStyle: 'none',
                        background: gradientFromColor,
                        borderRadius: blockRadius,
                        '+ li': {
                            marginBlockStart: controlHeightXS,
                        },
                    },
                },
                [`${skeletonParagraphCls}> li:last-child:not(:first-child):not(:nth-child(2))`]: {
                    width: '61%',
                },
            },
            [`&-round ${componentCls}-content`]: {
                [`${skeletonTitleCls}, ${skeletonParagraphCls} > li`]: {
                    borderRadius,
                },
            },
        },
        [`${componentCls}-with-avatar ${componentCls}-content`]: {
            // Title
            [skeletonTitleCls]: {
                marginBlockStart: marginSM,
                [`+ ${skeletonParagraphCls}`]: {
                    marginBlockStart: paragraphMarginTop,
                },
            },
        },
        // Skeleton element
        [`${componentCls}${componentCls}-element`]: Object.assign(Object.assign(Object.assign(Object.assign({ display: 'inline-block', width: 'auto' }, genSkeletonElementButton(token)), genSkeletonElementAvatar(token)), genSkeletonElementInput(token)), genSkeletonElementImage(token)),
        // Skeleton Block Button, Input
        [`${componentCls}${componentCls}-block`]: {
            width: '100%',
            [skeletonButtonCls]: {
                width: '100%',
            },
            [skeletonInputCls]: {
                width: '100%',
            },
        },
        // With active animation
        [`${componentCls}${componentCls}-active`]: {
            [`
        ${skeletonTitleCls},
        ${skeletonParagraphCls} > li,
        ${skeletonAvatarCls},
        ${skeletonButtonCls},
        ${skeletonInputCls},
        ${skeletonImageCls}
      `]: Object.assign({}, genSkeletonColor(token)),
        },
    };
};
// ============================== Export ==============================
export const prepareComponentToken = (token) => {
    const { colorFillContent, colorFill } = token;
    const gradientFromColor = colorFillContent;
    const gradientToColor = colorFill;
    return {
        color: gradientFromColor,
        colorGradientEnd: gradientToColor,
        gradientFromColor,
        gradientToColor,
        titleHeight: token.controlHeight / 2,
        blockRadius: token.borderRadiusSM,
        paragraphMarginTop: token.marginLG + token.marginXXS,
        paragraphLiHeight: token.controlHeight / 2,
    };
};
export default genStyleHooks('Skeleton', (token) => {
    const { componentCls, calc } = token;
    const skeletonToken = mergeToken(token, {
        skeletonAvatarCls: `${componentCls}-avatar`,
        skeletonTitleCls: `${componentCls}-title`,
        skeletonParagraphCls: `${componentCls}-paragraph`,
        skeletonButtonCls: `${componentCls}-button`,
        skeletonInputCls: `${componentCls}-input`,
        skeletonImageCls: `${componentCls}-image`,
        imageSizeBase: calc(token.controlHeight).mul(1.5).equal(),
        borderRadius: 100, // Large number to make capsule shape
        skeletonLoadingBackground: `linear-gradient(90deg, ${token.gradientFromColor} 25%, ${token.gradientToColor} 37%, ${token.gradientFromColor} 63%)`,
        skeletonLoadingMotionDuration: '1.4s',
    });
    return [genBaseStyle(skeletonToken)];
}, prepareComponentToken, {
    deprecatedTokens: [
        ['color', 'gradientFromColor'],
        ['colorGradientEnd', 'gradientToColor'],
    ],
});
