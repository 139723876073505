import React from 'react';
import { ReloadOutlined } from '@ant-design/icons';
import Button from '../button';
import Spin from '../spin';
const defaultSpin = React.createElement(Spin, null);
export default function QRcodeStatus({ prefixCls, locale, onRefresh, statusRender, status, }) {
    const defaultExpiredNode = (React.createElement(React.Fragment, null,
        React.createElement("p", { className: `${prefixCls}-expired` }, locale === null || locale === void 0 ? void 0 : locale.expired),
        onRefresh && (React.createElement(Button, { type: "link", icon: React.createElement(ReloadOutlined, null), onClick: onRefresh }, locale === null || locale === void 0 ? void 0 : locale.refresh))));
    const defaultScannedNode = React.createElement("p", { className: `${prefixCls}-scanned` }, locale === null || locale === void 0 ? void 0 : locale.scanned);
    const defaultNodes = {
        expired: defaultExpiredNode,
        loading: defaultSpin,
        scanned: defaultScannedNode,
    };
    const defaultStatusRender = (info) => defaultNodes[info.status];
    const mergedStatusRender = statusRender !== null && statusRender !== void 0 ? statusRender : defaultStatusRender;
    return mergedStatusRender({
        status,
        locale,
    });
}
