import { defaultPrefixCls } from '../config-provider';
// ================== Collapse Motion ==================
const getCollapsedHeight = () => ({ height: 0, opacity: 0 });
const getRealHeight = (node) => {
    const { scrollHeight } = node;
    return { height: scrollHeight, opacity: 1 };
};
const getCurrentHeight = (node) => ({ height: node ? node.offsetHeight : 0 });
const skipOpacityTransition = (_, event) => (event === null || event === void 0 ? void 0 : event.deadline) === true || event.propertyName === 'height';
const initCollapseMotion = (rootCls = defaultPrefixCls) => ({
    motionName: `${rootCls}-motion-collapse`,
    onAppearStart: getCollapsedHeight,
    onEnterStart: getCollapsedHeight,
    onAppearActive: getRealHeight,
    onEnterActive: getRealHeight,
    onLeaveStart: getCurrentHeight,
    onLeaveActive: getCollapsedHeight,
    onAppearEnd: skipOpacityTransition,
    onEnterEnd: skipOpacityTransition,
    onLeaveEnd: skipOpacityTransition,
    motionDeadline: 500,
});
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SelectPlacements = ['bottomLeft', 'bottomRight', 'topLeft', 'topRight'];
const getTransitionName = (rootPrefixCls, motion, transitionName) => {
    if (transitionName !== undefined) {
        return transitionName;
    }
    return `${rootPrefixCls}-${motion}`;
};
export { getTransitionName };
export default initCollapseMotion;
